<template>
  <div class="departure-sdchedule" ref="departureSdchedule">
    <div class="function-buttons" ref="functionButtons" v-show="false"></div>
    <div class="form-area" ref="form">
      <search-form @getFormVal="search">
        <el-button
          type="primary"
          size="small"
          :disabled="tableData.length === 0"
          :loading="downLoadStatus"
          @click="exportData"
          >导出</el-button
        >
      </search-form>
    </div>
    <el-table
      :data="
        tableData.slice(
          (form.currentPage - 1) * form.pageSize,
          form.currentPage * form.pageSize
        )
      "
      stripe
      :height="tableHeight"
      width="100%"
    >
      <el-table-column label="序号" type="index" width="70"></el-table-column>
      <el-table-column
        prop="workTime"
        label="排班日期"
        width="120"
        :formatter="formatTime"
      ></el-table-column>
      <el-table-column
        prop="banCiName"
        label="班次"
        width="120"
      ></el-table-column>
      <el-table-column prop="cph" label="车牌号" width="100"></el-table-column>
      <el-table-column prop="driverName" label="司机"></el-table-column>
      <el-table-column
        :label="head"
        v-for="(head, key, ele) in header"
        :key="ele"
      >
        <template slot-scope="scope">{{ scope.row.startTimes[key] }}</template>
      </el-table-column>
    </el-table>
    <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { querySchedulingList } from '@/api/lib/bus-api'
import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import { getCurent, formatDay } from "@/common/utils/index";
import searchForm from './components/form.vue'

export default {
  name: 'departureSdchedule',
  components: {
    searchForm
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        startTime: null,
        endTime: null,
        lineId: null,
        currentPage: 1,
        pageSize: 10
      },
      header: [],
      total: 0,
      exportForm: {}, //导出需要的数据
      downLoadStatus: false, //导出

    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.departureSdchedule.clientHeight
      let buttonsHeight = this.$refs.functionButtons.clientHeight
      let formHeight = this.$refs.form.clientHeight
      let paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10
    },
    //导出
    exportData () {
      this.downLoadStatus = true;
      let data = {
        baseUrl: "bus",
        fileName: `发车时刻表-${getCurent()}`,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: '/schedulingManage/startTimeTableExport',
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 提交
    search (val) {
      this.form = { ...this.form, ...val };
      this.getDataList()
    },

    // 获取列表
    getDataList () {
      let data = { ...this.form }
      delete data.currentPage
      delete data.pageSize
      this.header = []
      querySchedulingList(data)
        .then((res) => {
          if (res.code === 1000) {
            let flag = true;
            // 组装动态数据
            if (res.data.length > 0) {
              res.data[0].startTimes.forEach(item => {
                this.header.push(flag ? "主站" : "副站");
                flag = !flag;
              });
              this.tableData = res.data
              this.total = res.data.length
            } else {
              this.tableData = []
              this.total = 0
            }
          } else {
            this.tableData = []
            this.total = 0
            this.$message.error(res.msg)
          }
          this.exportForm = { ...data };

        })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
    },

    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
    },
    formatTime (row) {
      return formatDay(row.workTime)
    },
  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.departure-sdchedule {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.theme-project-bus {
  .departure-sdchedule {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
